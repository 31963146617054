import { EN_DATA } from "../languages/EN";
import "../css/Expertise.scss";

export const Expertise = () => {
  const data = EN_DATA.homepage_management.expertise_mgmt;

  return (
    <div className="expertise-mgmt">
      <div className="heading">{data.heading}</div>
      <div className="expertise-main">
        {data.expertise_items.map((item, index) => {
          const words = item.heading.split(" ");
          const firstWord = words[0];
          const secondWord = words.slice(1).join(" ");
          return (
            <div
              key={index}
              className="expertise-container"
              style={{
                backgroundImage: `url(${item.bg_image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="logo">
                <img className="m-auto" src={item.logo} alt="expertise logo" />
              </div>
              <div className="sub-heading clr">
                {firstWord}&nbsp;
                <span className="ylw-clr">{secondWord}</span>
              </div>
              <div className="sub-description">{item.description}</div>{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};
