import React from "react";
import { useNavigate } from "react-router-dom";

const HomeServices = () => {
  const navigate = useNavigate();

  const services = [
    {
      title: "Digital Transformation",
      description:
        "Embrace the digital age with confidence. We help you navigate the complex landscape of digital transformation, ensuring your business thrives in the modern world.",
      img: "https://cdn.algostrauss.com/experise/service_digital_transformation1.png",
      icon: "https://cdn.algostrauss.com/experise/service_digiital_transformation_small.png",
      link: "/services/digital-transformation",
    },
    {
      title: "AI and Machine Learning Solutions",
      description:
        "Harness the potential of AI for transformative insights and automation.",
      img: "https://cdn.algostrauss.com/experise/service_cloud_solutions1.png",
      icon: "https://cdn.algostrauss.com/experise/service_data_science_small.png",
      link: "/services/AI-ML-solutions",
    },
    {
      title: "Cloud Solutions",
      description:
        "Seamlessly migrate to the cloud for enhanced scalability, security, and cost-efficiency. Our cloud experts will guide you through every step of the journey.",
      img: "https://cdn.algostrauss.com/experise/service_market_analytics1.png",
      icon: "https://cdn.algostrauss.com/experise/service_clod_solutions_small.png",
      link: "/services/cloud-solutions",
    },
    {
      title: "Market Analytics",
      description:
        "Transform raw data into actionable insights. Leverage the power of data analytics to make informed decisions and drive growth.",
      img: "https://cdn.algostrauss.com/experise/service_ai_and_machine_learning_solutions1.png",
      icon: "https://cdn.algostrauss.com/experise/service_marketing_small.png",
      link: "/services/market-analytics",
    },
  ];

  return (
    <div>
      <h2 className="border-b-2 border-orange font-bold w-max text-black">
        Our Services
      </h2>
      <p className="lg:max-w-[55%] mb-10">
        “Fostering innovative digital experiences by utilizing product design,
        user experience strategy, advanced AI & Machine Learning methods, and
        technology facilitation.”
      </p>
      <div className="grid md:grid-cols-2 gap-10">
        {services.map((service, i) => (
          <div
            onClick={() => navigate(service.link)}
            key={i}
            className="bg-orange rounded-lg pl-5 relative hover:scale-[1.05] transition-all ease-in-out duration-500 cursor-pointer"
          >
            <img
              className="absolute -top-9 -right-4 w-max h-max"
              src={service.icon}
              alt=""
            />
            <div className="bg-white flex flex-col lg:flex-row items-center h-full px-3 py-2 rounded-r-md gap-4">
              <img src={service.img} alt="" />
              <div>
                <h1 className="text-orange text-2xl">{service.title}</h1>
                <p>{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeServices;
