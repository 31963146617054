export const seoTags = {
  homepage_seoTags: {
    title: "AlgoStrauss - IT services and consulting",
    description:
      "Empower your business with top-tier IT services from AlgoStrauss. Specializing in IT consulting, managed services, Advanced Analytics, AI Consulting and cloud solutions, we drive your success through technology. Explore our comprehensive IT solutions today.",
    keywords:
      "Digital Transformation Services, AI and Machine Learning Services, Cloud Solutions Services," +
      " Market Analytics Services, Web Development Services, Data Science Services, App Development Services," +
      " Software Development Services, Online Training Services, Expertise in Services, Consulting Services," +
      " Technology Solutions, Expertise in Web Development, Data Science Expertise, Software Development " +
      "Expertise, Technology Expertise, Digital Expertise, Industry-specific Expertise, Professional Expertise," +
      " Skilled Consultants, Experienced Professionals, Growth Catalysts, Accelerating Growth, Business Growth," +
      " Digital Transformation for Growth, Innovation for Growth, AI and ML for Growth, Technology-Driven Growth," +
      " Artificial Intelligence Solution, Machine Learning Solution, AI Consulting, ML Automation, AI Implementation," +
      " ML Algorithm, Data-Driven AI, Innovative Education, Future-Shaping Solution, Educational Technology, EdTec," +
      " Tech-Driven Education, Future-Focused Education, Innovation in Education, Expert Consultant, IIT Graduate," +
      " Skilled Professional, Industry Expert, Knowledgeable Consultant, Experienced Consultant," +
      " Up-to-Date Expertise, Client-Centric Approach, Customer Satisfaction, Client-Focused Service," +
      " Prioritizing Customer Need, Customer-Centered Solution, Customer Delight, Affordable Service," +
      " Cost-Effective Solution, Competitive Rate, Quality Services at a Reasonable Price," +
      " Affordable Technology Solution, Competitive Pricing Model, Full-Stack Web Development," +
      " Front-End Development, Back-End Development, Web App Development, Web Design, Website Development," +
      " Web Development Training, Data Analysis, Machine Learning, Data Engineering, Data Science Training," +
      " Predictive Modeling, Data Science Expertise, Cloud Migration, Cloud Service, Cloud Security," +
      " Scalable Cloud Solution, Cost-Efficient Cloud, Digital Adoption, Digital Transformation Confidence," +
      " Modernize Your Business, Thrive in the Digital Ag, Digital Growth Strategies",
    ogTitle: "AlgoStrauss - IT services and consulting",
    ogDescription:
      "Empower your business with top-tier IT services from AlgoStrauss. Specializing in IT consulting, managed services, Advanced Analytics, AI Consulting and cloud solutions, we drive your success through technology. Explore our comprehensive IT solutions today.",
    ogImage: "https://cdn.algostrauss.com/images/software_develop.png",
    ogUrl: "https://www.algostrauss.com",
  },
  aiService_seoTags: {
    title: "AI and ML Solutions | AlgoStrauss",
    description:
      "In today's dynamic business landscape, data is the driving force behind strategic decision-making." +
      " At AlgoStrauss, we specialize in Market Analytics, leveraging data-driven insights to empower your " +
      "organization's growth and success.",
    keywords:
      "Data Analysis, Machine Learning, Data Engineering, Data Science Training," +
      " Predictive Modeling, Data Science Expertise",
    ogTitle: "AI and ML Solutions | AlgoStrauss",
    ogDescription:
      "In today's dynamic business landscape, data is the driving force behind strategic decision-making. " +
      "At AlgoStrauss, we specialize in Market Analytics, leveraging data-driven insights to empower your " +
      "organization's growth and success.",
    ogImage: "https://cdn.algostrauss.com/images/software_develop.png",
    ogUrl: "https://www.algostrauss.com/#/services/AI-ML-solutions",
  },
  cloudSolutionService_seoTags: {
    title: "Cloud Solutions | AlgoStrauss",
    description:
      "In today's rapidly evolving digital landscape, the cloud has become the cornerstone of innovation and business scalability. At AlgoStrauss, we understand the transformative potential of cloud solutions, and our mission is to guide your organization through a seamless migration to the cloud, harnessing its full capabilities.",
    keywords:
      "Cloud Migration, Cloud Services, Cloud Security, Scalable Cloud Solutions, Cost-Efficient Cloud",
    ogTitle: "Cloud Solutions | AlgoStrauss",
    ogDescription:
      "In today's rapidly evolving digital landscape, the cloud has become the cornerstone of innovation and business scalability. At AlgoStrauss, we understand the transformative potential of cloud solutions, and our mission is to guide your organization through a seamless migration to the cloud, harnessing its full capabilities.",
    ogImage: "https://cdn.algostrauss.com/services/Infrastructure_as_code.png",
    ogUrl: "https://www.algostrauss.com/services/cloud-solutions",
  },
};
