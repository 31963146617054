import React from "react";
import Navigationbar from "../reusableComponents/Navbar";
import Footer from "../reusableComponents/Footer";

const Layout = ({ children }) => {
  return (
    <div className="parent-container">
      {/* Navbar Section */}
      <header className="navbar-container">
        <Navigationbar />
      </header>
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
