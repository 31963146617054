import React from "react";
import "../css/ContactForm.scss";
import SubmitForm from "./SubmitForm";
import { EN_DATA } from "../languages/EN";
import PhoneLogo from "../assets/icons/phone_logo.svg";
import EmailLogo from "../assets/icons/Email_Logo.svg";
const ContactForm = () => {
  const data = EN_DATA.homepage_management.contact_mgmt;

  return (
    <div className="submit-form-main">
      <div className="contact-container">
        <div className="heading">{data.heading}</div>
        <div className="sub-heading rounded-lg">{data.sub_heading}</div>
        <div className="description">{data.description}</div>
        <div className="contact-us-main">
          <div className="number-container">
            <div>
              <img src={PhoneLogo} alt="No_image_found" />
            </div>{" "}
            <div>
              <div className="text drk">Our Number</div>
              <a href="tel:+919588288170" className="text lgt no-underline">+91 9588288170</a>
            </div>
          </div>
          <div className="number-container">
            <div>
              <img src={EmailLogo} alt="No_image_found" />
            </div>
            <div>
              {" "}
              <div className="text drk">Our Email</div>
              <a href="mailto:office@algostrauss.com" className="text lgt no-underline"> office@algostrauss.com</a>
            </div>
          </div>
        </div>
      </div>
      <SubmitForm />
    </div>
  );
};

export default ContactForm;
