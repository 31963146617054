import React, { useEffect, useState } from "react";
// import { isMobile } from "react-device-detect";
// import { ReactComponent as Adv1Img } from "../assets/icons/AdvIconOne.svg";
// import { ReactComponent as Adv2Img } from "../assets/icons/AdvIconSecond.svg";
// import { ReactComponent as Adv3Img } from "../assets/icons/AdvIconThird.svg";
// import { ReactComponent as Adv4Img } from "../assets/icons/AdvIconFourth.svg";
// import { ReactComponent as Adv5Img } from "../assets/icons/AdvIconFifth.svg";
import "../css/Homepage.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "bootstrap/dist/css/bootstrap.min.css";
// import Navigationbar from "../reusableComponents/Navbar";
// import apis from "../config/apiEndPoint";
// import Feedback from "./Feedback";
// import Legacy from "./Legacy";
// import OurClients from "./OurClients";
// import Subscribe from "./Subscribe";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
// import { APP_ROUTES } from "../config/routes";
// import { editProductDetailURL } from "../commonMethods/commonMethods";
// import { bannerImages } from "../config/utils";
import { HowWeWork } from "./HowWeWork";
import { Expertise } from "./Expertise";
import ContactForm from "../reusableComponents/ContactForm";
import SkillsSection from "../reusableComponents/SkillsHomePage";
import TrainingProgram from "../reusableComponents/TrainingProgram";
import Layout from "../layout/Layout";
import HomeServices from "../reusableComponents/HomeServices";
import { Helmet } from "react-helmet";
import { seoTags } from "../config/seoTags";

let heroImg = "https://cdn.algostrauss.com/images/HeroSectionImage.png";
// let servicesImg = "https://cdn.algostrauss.com/images/ServicesImage1.JPG";

const Homepage = () => {
  const navigate = useNavigate();
  const redirectTOPath = (path) => {
    navigate(path);
  };

  useEffect(() => {
    AOS.init({ duration: 700, offset: 40 });
  }, []);

  const [currentTitle, setCurrentTitle] = useState(0);
  const titles = [
    "Digital Transformation",
    "AI and ML Solutions",
    "Cloud Solutions",
    "Market Analytics",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentTitle === titles.length - 1) {
        setCurrentTitle(0);
      } else {
        setCurrentTitle((prev) => prev + 1);
      }
    }, 2000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [currentTitle, titles.length]);

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  const HeroSection = () => {
    return (
      <div
        style={{
          backgroundImage: `linear-gradient(45deg, rgba(27, 53, 97, .8) , rgba(27, 53, 97, .3)), url(${heroImg})`,
        }}
        className="bg-center bg-cover bg-no-repeat w-full h-max py-40"
      >
        <div className="">
          <Helmet>
            <title>{seoTags.homepage_seoTags.title}</title>
            <meta
              name="description"
              content={seoTags.homepage_seoTags.description}
            />
            <meta name="keywords" content={seoTags.homepage_seoTags.keywords} />
            <meta
              property="og:title"
              content={seoTags.homepage_seoTags.ogTitle}
            />
            <meta
              property="og:description"
              content={seoTags.homepage_seoTags.ogDescription}
            />
            <meta
              property="og:image"
              content={seoTags.homepage_seoTags.ogImage}
            />
            <meta property="og:url" content={seoTags.homepage_seoTags.ogUrl} />
            <meta property="og:type" content="website" />
          </Helmet>
          <div className="text-white text-2xl sm:text-3xl md:text-4xl mb-4 font-bold text-center">
            <div className="mb-2">Catalyzing Growth</div>
            <div>
              via{" "}
              <span className="text-orange transition-opacity ease-in-out duration-500">
                {titles[currentTitle]}
              </span>
            </div>
          </div>
          <div className="hero-section-small-heading">
            Shaping Futures through Innovation and Education
          </div>
        </div>{" "}
      </div>
    );
  };
  //
  return (
    <Layout>
      {/* Hero section (banner) */}
      <div className="hero-container">
        <HeroSection />
      </div>
      <div className="middle-section">
        {/* Skills section */}
        <div className="skills-container" data-aos="fade-up">
          <SkillsSection />
        </div>
        {/* Skills section */}
        <div className="xsm:px-4 sm:px-12 py-10" data-aos="fade-up">
          <HomeServices />
        </div>
      </div>
      {/* Subscribe section */}
      <div>
        <HowWeWork />
        <TrainingProgram />
        <Expertise />
        <div id="contact-form">
          <ContactForm />
        </div>
      </div>
    </Layout>
  );
};
export default Homepage;
