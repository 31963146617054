import React from 'react';

const TrainingProgram = () => {


  const programs = [
    {
      title: "Web Development",
      points: [
        "Full-Stack Web Development: Build interactive web apps from scratch.",
        "Front-End Development: Craft stunning user interfaces with HTML, CSS, and JavaScript.",
        "Back-End Development: Learn server-side technologies for robust applications."
      ],
      icon: 'https://cdn.algostrauss.com/experise/training_web_small.png',
      img: 'https://cdn.algostrauss.com/experise/training_web.png'
    },
    {
      title: "App Development",
      points: [
        "Mobile App Development: Create user-friendly mobile apps for iOS and Android.",
        "Comprehensive Curriculum: Gain proficiency, covering both iOS and Android platforms.",
        "Real-World Projects: Apply your skills to practical projects, building a personal portfolio"
      ],
      icon: 'https://cdn.algostrauss.com/experise/training_software_small.png',
      img: 'https://cdn.algostrauss.com/experise/training_app.png'
    },
    {
      title: "Data Science",
      points: [
        "Data Analysis: Explore data manipulation, visualization, and statistical analysis.",
        "Machine Learning: Dive into machine learning algorithms and predictive modeli",
        "Data Engineering: Develop skills to collect, clean, and transform data"
      ],
      icon: 'https://cdn.algostrauss.com/experise/training_data_small.png',
      img: 'https://cdn.algostrauss.com/experise/training_data.png'
    },
  ]

  return (
    <div style={{ background: 'linear-gradient(99deg, #C3DEEB 0.37%, rgba(195, 222, 235, 0.00) 103.44%)' }} className='xsm:px-4 sm:px-12 py-10'>
      <div className='flex flex-col lg:flex-row items-center'>
        <div className='flex-1 lg:border-r border-orange'>
          <h2 className="border-b-2 border-orange font-bold w-max text-black mb-10">
            Training Program
          </h2>
        </div>
        <div className='hidden lg:block flex-[.5]'></div>
        <div className='flex-1'>
          <h3 className='text-orange'>Why Choose AlgoStrauss?</h3>
          <p>Join Algostrauss Training to unlock your potential in web/app development and data science. Benefit from expert instructors, hands-on learning, and flexible options, all designed to help you excel in the digital age. Choose your path and take the next step towards a brighter, tech-driven future with Algostrauss.</p>
        </div>
      </div>
      <div className='grid lg:grid-cols-3 gap-4 xsm:mt-10 lg:mt-16'>
        {
          programs.map((p, i) => (
            <div key={i} className='bg-white z-[1] p-3 flex flex-col mt-14 lg:mt-0 relative trainingCard'>
              {/* <div style={{ clipPath: 'polygon(100% 0, 0 0, 100% 35%)' }} className='w-full h-full left-0 top-0 z-[-1] bg-orange absolute'></div> */}
              <img className='w-[50%] mx-auto -mt-20 mb-4' src={p.img} alt="" />
              <div className='flex items-center gap-2 justify-center mb-3'>
                <img src={p.icon} alt="" />
                <h3 className='font-bold my-auto'>{p.title}</h3>
              </div>
              <ul className='list-disc font-semibold'>
                {
                  p.points.map((point, i) => (
                    <li key={i}>
                      {point}
                    </li>
                  ))
                }
              </ul>
              <div className='flex items-center justify-center mt-auto'>
                <p className='bg-orange px-3 py-2 text-white rounded-md cursor-default'>Coming Soon</p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default TrainingProgram;
