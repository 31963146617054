import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import ContactForm from "../reusableComponents/ContactForm";
import { ExpertiesIntroSection } from "../reusableComponents/IntroSection";
import {
  CloudIcon,
  Globe,
  CursorGlobe,
  NetworkIconBig,
} from "../assets/images/svg/SvgImages";
import { useNavigate } from "react-router-dom";
import { EN_DATA } from "../languages/EN";
import line from "../assets/images/line.svg";
import ExpertiesWhyUs from "../reusableComponents/ExpertiesWhyUs";

const DataExperties = () => {
  const navigate = useNavigate();

  const expertise = [
    {
      title: "Data Analysis and Visualization",
      description:
        "We excel in analyzing complex data sets to extract valuable insights. Our data visualization techniques help you understand your data at a glance, making it easier to identify trends and patterns.",
      icon: "https://cdn.algostrauss.com/experise/data+science/data analysis and visualization.png",
    },
    {
      title: "Predictive Analytics",
      description:
        "Anticipate future trends and make informed decisions with our predictive analytics solutions. We use advanced modeling techniques to forecast outcomes and optimize strategies.",
      icon: "https://cdn.algostrauss.com/experise/data+science/predictive analytics.png",
    },
    {
      title: "Machine Learning and AI",
      description:
        "Managing and updating your website should be a breeze. We work with popular CMS platforms like WordPress, Drupal, and Joomla to provide you with easy-to-use interfaces for content management.",
      icon: "https://cdn.algostrauss.com/experise/data+science/machine learning and ai.png",
    },
    {
      title: "Big Data Solutions",
      description:
        "We specialize in handling massive volumes of data. Our big data solutions enable you to store, process, and extract insights from vast data sets efficiently.",
      icon: "https://cdn.algostrauss.com/experise/data+science/big+data+solutions.png",
    },
    {
      title: "Data-driven Strategy",
      description:
        "We help you formulate data-driven strategies that align with your business goals, ensuring that data becomes a valuable asset for your organization.",
      icon: "https://cdn.algostrauss.com/experise/data+science/data driven strategy.png",
    },
    {
      title: "Data Governance and Security",
      description:
        "Protecting your data is paramount. We implement robust data governance and security measures to ensure the confidentiality, integrity, and availability of your data.",
      icon: "https://cdn.algostrauss.com/experise/data+science/data governance and security.png",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <ExpertiesIntroSection
        icon="https://cdn.algostrauss.com/experise/banner_icon.png"
        title="Introduction"
        description="We specialize in Digital Transformation, Cloud Solutions, AI & Machine Learning, and Market Analytics, empowering businesses to thrive in the digital era. At AlgoStrauss, we are committed to empowering your organization's growth."
      />
      <div className="bg-white xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange">
        <div className="flex flex-col xl:flex-row gap-2 md:gap-5 items-center">
          <div className="flex w-full gap-3 flex-[1.3]">
            <img
              className="h-max"
              src="https://cdn.algostrauss.com/experise/data_science.png"
              alt="icon"
            ></img>
            {/* <div>
              <NetworkIconBig />
            </div> */}
            <div className="">
              <h2 className="font-bold 2xl:text-4xl">
                <span className="text-orange block sm:inline-block">Data</span>{" "}
                Science
              </h2>
              <p className="font-medium hidden sm:block text-lg 2xl:text-xl text-[#1E1E1ECC]">
                Leverage our expertise in software development, covering
                front-end, back-end, database management, and software
                architecture. Empower yourself with the knowledge and skills to
                craft efficient and innovative software solutions. Join us and
                unlock the potential to create impactful software tailored to
                modern needs.
              </p>
            </div>
          </div>
          <p className="font-medium sm:hidden text-lg text-[#1E1E1ECC]">
            At AlgoStrauss, we understand that the future belongs to those who
            embrace digital transformation. Our mission is to guide businesses
            on their transformational journey by leveraging cutting-edge
            technologies and innovative strategies.
          </p>
          <div className="flex-1">
            <img
              className="max-h-60 m-auto"
              src="https://cdn.algostrauss.com/experise/experise_icon1.png"
              alt="icon"
            />
          </div>
        </div>
      </div>

      <div className="bg-black xsm:px-2 md:px-20 py-10">
        <h2 className="border-b-2 border-orange font-bold w-max mb-10 xl:mb-20 text-white">
          Our Expertise
        </h2>
        <div className="grid lg:grid-cols-2 gap-x-10 gap-y-20 mt-5">
          {expertise.map((exp, i) => {
            const title = exp.title.split(" ");
            const first = title[0];
            const rest = title.splice(1).join(" ");
            console.log(rest);

            return (
              <div className="bg-[#F6F6F6] relative py-4 pl-20 pr-4 shadow-[6px_6px_10px_rgba(255,135,0,.5)] rounded-sm">
                <h1 className="text-3xl font-bold">
                  <span className="text-orange">{first} </span>
                  {rest}
                </h1>
                <p className="font-medium">{exp.description}</p>
                <div className="absolute -top-12 left-2 h-20 w-20">
                  <div className="pentagon bg-orange h-full w-full flex items-center justify-center [&_svg]:m-auto">
                    <img src={exp.icon} alt="" />
                  </div>
                  <img
                    className="absolute h-full w-max bg-orange top-[98%] left-[50%] -translate-x-[50%]"
                    src={line}
                    alt="line"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange text-white">
        <h3 className="text-[#1E1E1E] mx-auto pt-3 mb-10 font-bold border-b-2 border-orange w-max">
          <span className="text-orange">Our</span> Other Expertises
        </h3>
        <div className="grid lg:grid-cols-3 gap-10">
          {EN_DATA.homepage_management.expertise_mgmt.expertise_items.map(
            (exp, i) => {
              let first;
              let rest;
              const headings = exp.heading.split(" ");
              first = headings[0];
              rest = headings[1];

              let thisPage = first.includes("Data");
              console.log(thisPage);

              return (
                <div
                  className={`${
                    thisPage ? "bg-orange" : "bg-[#1E1E1E]"
                  } w-auto xl:w-max px-3 py-2 rounded-lg h-full shadow-[0px_4px_10px_rgba(0,0,0,0.5)]`}
                >
                  <img
                    className="m-auto h-16"
                    src={thisPage ? exp.activeIcon : exp.icon}
                    alt="icon"
                  />
                  <h4 className="text-center mt-2 font-bold">
                    <span
                      className={`${thisPage ? "text-black" : "text-orange"}`}
                    >
                      {first}
                    </span>{" "}
                    {rest}
                  </h4>
                  <p className="text-sm 2xl:text-lg text-center font-medium">
                    {exp.description}
                  </p>
                  <div className="text-center">
                    <button
                      onClick={() => navigate(exp.webPageLink)}
                      className={`${
                        thisPage
                          ? "hidden"
                          : "bg-orange hover:bg-white hover:text-orange"
                      } px-3 py-1 rounded-lg m-auto active:scale-[.95]`}
                    >
                      Explore
                    </button>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>

      <ExpertiesWhyUs />

      <div id="contact-form">
        <ContactForm />
      </div>
    </Layout>
  );
};

export default DataExperties;
