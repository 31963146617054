import React, { useEffect } from "react";
import Layout from "../layout/Layout";
// import bannerBg from "../assets/images/ServicePageBanner.png";
import {
  // AiIcon,
  AiIconBig,
  // CloudIcon,
  CloudIconBig,
  CursorGlobeSmall,
  Globe,
  NetworkIcon,
  // NetworkIconBig,
} from "../assets/images/svg/SvgImages";

import icon1 from "../assets/images/services/cloud/i1.svg";
import icon2 from "../assets/images/services/cloud/i2.svg";
import icon3 from "../assets/images/services/cloud/i3.svg";
import icon4 from "../assets/images/services/cloud/i4.svg";
import icon5 from "../assets/images/services/cloud/i5.svg";
import icon6 from "../assets/images/services/cloud/i6.svg";

import ContactForm from "../reusableComponents/ContactForm";
import { useNavigate } from "react-router-dom";
import { ServicesIntroSection } from "../reusableComponents/IntroSection";
import { Helmet } from "react-helmet";
import { seoTags } from "../config/seoTags";

const AiServices = () => {
  const navigate = useNavigate();

  const bannerIcon1 =
    "https://cdn.algostrauss.com/services/digital_transformation_icons/bannerIcon1.svg";
  const bannerIcon2 =
    "https://cdn.algostrauss.com/services/digital_transformation_icons/bannerIcon2.svg";
  const bannerIcon3 =
    "https://cdn.algostrauss.com/services/digital_transformation_icons/bannerIcon3.svg";
  const bannerIcon4 =
    "https://cdn.algostrauss.com/services/digital_transformation_icons/bannerIcon4.svg";

  const cloud1 =
    "https://cdn.algostrauss.com/services/ai_strategy_and_consulting.png";
  const cloud2 =
    "https://cdn.algostrauss.com/services/machine_learning_development.png";
  const cloud3 =
    "https://cdn.algostrauss.com/services/data_mining_and_insights.png";
  const cloud4 =
    "https://cdn.algostrauss.com/services/computer_vision_solutions.png";
  const cloud5 =
    "https://cdn.algostrauss.com/services/nlp_and_text_analysis.png";
  const cloud6 =
    "https://cdn.algostrauss.com/services/ai-powered_automation.png";

  const offers = [
    {
      title: "AI Strategy and Consulting",
      desc: "Crafting a successful AI strategy is paramount. We collaborate with your team to understand your unique business challenges and goals, developing a comprehensive AI roadmap that drives your organization forward.",
      img: cloud1,
      icon: icon1,
    },
    {
      title: "Machine Learning Development",
      desc: "Our team of data scientists and machine learning experts design and build custom machine learning models tailored to your specific needs. From predictive analytics to natural language processing, we've got you covered.",
      img: cloud2,
      icon: icon2,
    },
    {
      title: "Data Mining and Insights",
      desc: "Extracting valuable insights from your data is a game-changer. We utilize advanced data mining techniques to uncover trends, patterns, and actionable insights that empower your decision-making process.",
      img: cloud3,
      icon: icon3,
    },
    {
      title: "Computer Vision Solutions",
      desc: "Visual data is rich with potential. We create computer vision applications that can recognize and interpret images and videos, opening up new horizons for automation and analysis.",
      img: cloud4,
      icon: icon4,
    },
    {
      title: "NLP and Text Analysis",
      desc: "Understand and derive meaning from text data. Our NLP and text analysis solutions help you gain insights from unstructured text, automate content categorization, and improve customer interactions.",
      img: cloud5,
      icon: icon5,
    },
    {
      title: "AI-Powered Automation",
      desc: "Enhance efficiency through automation. We integrate AI into your processes and workflows, reducing manual tasks and driving operational excellence.",
      img: cloud6,
      icon: icon6,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="font-outfit">
        <Helmet>
          <title>{seoTags.aiService_seoTags.title}</title>
          <meta
            name="description"
            content={seoTags.aiService_seoTags.description}
          />
          <meta name="keywords" content={seoTags.aiService_seoTags.keywords} />
          <meta name="title" content={seoTags.aiService_seoTags.title} />
          <meta
            property="og:title"
            content={seoTags.aiService_seoTags.ogTitle}
          />
          <meta
            property="og:description"
            content={seoTags.aiService_seoTags.ogDescription}
          />
          <meta
            property="og:image"
            content={seoTags.aiService_seoTags.ogImage}
          />
          <meta property="og:url" content={seoTags.aiService_seoTags.ogUrl} />
          <meta property="og:type" key="og:type" content="website" />
        </Helmet>
        <ServicesIntroSection
          icon={<Globe />}
          title="Introduction"
          description="We specialize in Digital Transformation, Cloud Solutions, AI & Machine Learning, and Market Analytics, empowering businesses to thrive in the digital era. At AlgoStrauss, we are committed to empowering your organization's growth."
        />
        <div className="bg-white xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange">
          <div className="flex flex-col xl:flex-row gap-2 md:gap-5 items-center">
            <div className="flex w-full gap-3 flex-1">
              <div>
                <AiIconBig />
              </div>
              <div className="">
                <h2 className="font-bold 2xl:text-4xl">
                  <span className="text-orange block sm:inline-block">AI</span>{" "}
                  and ML Solutions
                </h2>
                <p className="font-medium hidden sm:block text-lg 2xl:text-xl text-[#1E1E1ECC]">
                  In today's dynamic business landscape, data is the driving
                  force behind strategic decision-making. At AlgoStrauss, we
                  specialize in Market Analytics, leveraging data-driven
                  insights to empower your organization's growth and success.
                </p>
              </div>
            </div>
            <p className="font-medium sm:hidden text-lg text-[#1E1E1ECC]">
              In a world driven by data, AI and Machine Learning are the keys to
              unlocking untapped potential and driving innovation. At
              AlgoStrauss, we are passionate about harnessing the power of
              artificial intelligence and machine learning to help businesses
              thrive in an increasingly data-centric landscape.
            </p>
            <div className="flex-1">
              <div className="flex gap-3 sm:gap-0 items-center justify-around">
                <img className="h-16 sm:h-auto" src={bannerIcon1} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon2} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon3} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon4} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="xsm:px-3 sm:px-20 py-10 sm:py-16 bg-black text-white">
          <h2 className="border-b-2 border-orange font-bold w-max mx-auto mb-10 xl:mb-20">
            What We Offer
          </h2>
          <div className="md:hidden">
            {offers.map((offer, i) => (
              <>
                <div
                  key={i}
                  className="flex items-center my-8 odd:flex-row-reverse"
                >
                  <div className="flex flex-1 gap-3">
                    <img className="h-max" src={offer.icon} alt="" />
                    <div>
                      <h3>{offer.title}</h3>
                      <div className="h-max">
                        <img
                          className="m-auto h-max rounded-lg"
                          src={offer.img}
                          alt=""
                        />
                      </div>
                      <p className="text-[#FFFFFFE5]">{offer.desc}</p>
                      {/* <button className='bg-orange px-[19px] py-[5px] rounded-md'>Get A Quote</button> */}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="hidden md:block 3xl:max-w-[85em] m-auto">
            {offers.map((offer, i) => (
              <>
                <div
                  key={i}
                  className="flex items-center my-8 even:flex-row-reverse"
                >
                  <div className="flex flex-1 gap-3">
                    <img className="h-max" src={offer.icon} alt="" />
                    <div>
                      <h3>{offer.title}</h3>
                      <p className="text-[#FFFFFFE5] 2xl:text-xl">
                        {offer.desc}
                      </p>
                      {/* <button className='bg-orange px-[19px] py-[5px] rounded-md xl:text-xl'>Get A Quote</button> */}
                    </div>
                  </div>
                  <div className="flex-1">
                    <img
                      className="m-auto h-max rounded-lg"
                      src={offer.img}
                      alt=""
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange text-white">
          <h3 className="text-[#1E1E1E] md:hidden mx-auto pt-3 mb-10 font-bold border-b-2 border-orange w-max">
            <span className="text-orange">Our</span> Other Services
          </h3>
          <div className="flex justify-center flex-col md:flex-row gap-10">
            <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
              <NetworkIcon className={"m-auto"} />
              <h4 className="text-center mt-2">
                <span className="text-orange">Market</span> Analytics
              </h4>
              <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                {" "}
                Seize Opportunities of Market with{" "}
                <span className="text-orange">AlgoStrauss</span>
              </p>
              <div className="text-center">
                <button
                  onClick={() => navigate("/services/market-analytics")}
                  className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                >
                  Explore
                </button>
              </div>
            </div>
            <div>
              <h3 className="text-[#1E1E1E] mx-auto pt-3 hidden md:block mb-10 font-bold border-b-2 border-orange w-max">
                <span className="text-orange">Our</span> Other Services
              </h3>
              <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
                <CursorGlobeSmall className={"m-auto [&_svg]:m-auto"} />
                <h4 className="text-center mt-2">
                  <span className="text-orange">Digital</span> Transformation
                </h4>
                <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                  Empowering Your Digital Journey with{" "}
                  <span className="text-orange">AlgoStrauss</span>
                </p>
                <div className="text-center">
                  <button
                    onClick={() => navigate("/services/digital-transformation")}
                    className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                  >
                    Explore
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
              <CloudIconBig className={"m-auto [&_svg]:m-auto"} />
              <h4 className="text-center mt-2">
                <span className="text-orange">Cloud</span> Solutions
              </h4>
              <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                Unlocking the Power of the Cloud with{" "}
                <span className="text-orange">AlgoStrauss</span>
              </p>
              <div className="text-center">
                <button
                  onClick={() => navigate("/services/cloud-solutions")}
                  className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                >
                  Explore
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-form">
        <ContactForm />
      </div>
    </Layout>
  );
};

export default AiServices;
