import React from "react";
import { scrollToContactForm } from "../commonMethods/commonMethods";

export const ServicesIntroSection = ({ title, description, icon }) => {

  const bannerBg = "https://cdn.algostrauss.com/images/service_page_banner.png";

  return (
    <div
      style={{ backgroundImage: `url(${bannerBg})` }}
      className="bg-cover bg-no-repeat bg-center w-full h-max pt-20 sm:pt-32 pb-10 bg-[#005d85]"
    >
      <div className="flex justify-center items-center h-full max-w-[90%] sm:max-w-[60%] 2xl:max-w-[60%] m-auto">
        <div className="text-white mt-14">
          <div className="relative w-max">
            <div className="absolute -top-[2.8em] sm:-top-[4em] transform rotate-90 sm:rotate-0 -right-[4.4em] sm:-left-[5.5em] xsm:[&_svg]:h-20 sm:[&_svg]:h-auto w-max">
              {icon}
            </div>
            <h1 className="border-b-2 border-orange font-bold w-max xl:text-4xl 2xl:text-6xl">
              {title}
            </h1>
          </div>
          <div>
            <p className="text-lg xl:text-xl 2xl:text-3xl">{description}</p>
            <button
              className="bg-orange rounded-md px-[19px] py-2.5 font-semibold text-lg xl:text-xl 2xl:text-3xl hover:bg-white active:scale-[.95] border-orange hover:text-orange "
              onClick={scrollToContactForm}
            >
              Get a Quote
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export const ExpertiesIntroSection = ({ title, description, icon }) => {

  const bannerBg = "https://cdn.algostrauss.com/images/service_page_banner.png";

  return (
    <div
      style={{ backgroundImage: `url(${bannerBg})` }}
      className="bg-cover bg-no-repeat bg-center w-full h-max pt-20 sm:pt-32 pb-10 bg-[#005d85]"
    >
      <div className="flex justify-center items-center h-full max-w-[90%] sm:max-w-[60%] 2xl:max-w-[60%] m-auto">
        <div className="text-white mt-14">
          <div className="relative w-max">
            <div className="absolute -top-[2.5em] sm:-top-[4.8em] transform rotate-[80deg] sm:rotate-0 -right-[3.6em] sm:-left-[4.8em] w-max">
              <img className="xsm:h-20 sm:h-auto" src={icon} alt="icon" />
            </div>
            <h1 className="border-b-2 border-orange font-bold w-max xl:text-4xl 2xl:text-6xl">
              {title}
            </h1>
          </div>
          <div>
            <p className="text-lg xl:text-xl 2xl:text-3xl">{description}</p>
            <button
              className="bg-orange rounded-md px-[19px] py-2.5 font-semibold text-lg xl:text-xl 2xl:text-3xl hover:bg-white active:scale-[.95] border-orange hover:text-orange "
              onClick={scrollToContactForm}
            >
              Get a Quote
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
