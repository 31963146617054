import React, { useEffect } from "react";
import Layout from "../layout/Layout";
// import bannerBg from "../assets/images/ServicePageBanner.png";
import {
  AiIcon,
  CloudIcon,
  // CursorGlobe,
  CursorGlobeSmall,
  Globe,
  NetworkIcon,
} from "../assets/images/svg/SvgImages";

import icon1 from "../assets/images/services/cloud/i1.svg";
import icon2 from "../assets/images/services/cloud/i2.svg";
import icon3 from "../assets/images/services/cloud/i3.svg";
import icon4 from "../assets/images/services/cloud/i4.svg";
import icon5 from "../assets/images/services/cloud/i5.svg";
import icon6 from "../assets/images/services/cloud/i6.svg";

import ContactForm from "../reusableComponents/ContactForm";
import { useNavigate } from "react-router-dom";
import { ServicesIntroSection } from "../reusableComponents/IntroSection";
import { Helmet } from "react-helmet";
import { seoTags } from "../config/seoTags";

const CloudServices = () => {
  const navigate = useNavigate();

  const bannerIcon1 =
    "https://cdn.algostrauss.com/services/cloud_solutions_icons/bannerIcon1.svg";
  const bannerIcon2 =
    "https://cdn.algostrauss.com/services/cloud_solutions_icons/bannerIcon2.svg";
  const bannerIcon3 =
    "https://cdn.algostrauss.com/services/cloud_solutions_icons/bannerIcon3.svg";
  const bannerIcon4 =
    "https://cdn.algostrauss.com/services/cloud_solutions_icons/bannerIcon4.svg";

  const cloud1 =
    "https://cdn.algostrauss.com/services/cloud_strategy_and_roadmap.png";
  const cloud2 =
    "https://cdn.algostrauss.com/services/cloud_migration_and_deployment.png";
  const cloud3 =
    "https://cdn.algostrauss.com/services/public_private_and_hybrid_cloud.png";
  const cloud4 =
    "https://cdn.algostrauss.com/services/Infrastructure_as_code.png";
  const cloud5 =
    "https://cdn.algostrauss.com/services/cloud_security_and_compliance.png";
  const cloud6 =
    "https://cdn.algostrauss.com/services/cloud_cost_optimization.png";

  const offers = [
    {
      title: "Cloud Strategy and Roadmap",
      desc: "Start your cloud journey with a well-defined strategy. We work closely with your team to understand your business goals and design a comprehensive cloud roadmap that aligns with your vision.",
      img: cloud1,
      icon: icon1,
    },
    {
      title: "Cloud Migration and Deployment",
      desc: "Transitioning to the cloud can be complex. We simplify the process by executing a smooth migration plan, ensuring minimal disruption and maximum benefits for your organization.",
      img: cloud2,
      icon: icon2,
    },
    {
      title: "Public, Private, and Hybrid Cloud",
      desc: "We offer expertise in public, private, and hybrid cloud solutions. Whether you need the agility of a public cloud, the control of a private cloud, or a customized hybrid approach, we have you covered.",
      img: cloud3,
      icon: icon3,
    },
    {
      title: "Infrastructure as Code (IaC)",
      desc: "Embrace infrastructure automation with IaC. We help you define your infrastructure as code, enabling rapid provisioning, scaling, and management of cloud resources.",
      img: cloud4,
      icon: icon4,
    },
    {
      title: "Cloud Security and Compliance",
      desc: "Protect your cloud assets and data. We implement robust security measures and ensure compliance with industry regulations, providing peace of mind in the cloud.",
      img: cloud5,
      icon: icon5,
    },
    {
      title: "Cloud Cost Optimization",
      desc: "Maximize the value of your cloud investments. Our experts analyze your cloud usage and optimize resource allocation to reduce costs while maintaining performance.",
      img: cloud6,
      icon: icon6,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="font-outfit">
        <Helmet>
          <title>{seoTags.cloudSolutionService_seoTags.title}</title>
          <meta
            name="description"
            content={seoTags.cloudSolutionService_seoTags.description}
          />
          <meta
            name="keywords"
            content={seoTags.cloudSolutionService_seoTags.keywords}
          />
          <meta
            property="og:title"
            content={seoTags.cloudSolutionService_seoTags.ogTitle}
          />
          <meta
            property="og:description"
            content={seoTags.cloudSolutionService_seoTags.ogDescription}
          />
          <meta
            property="og:image"
            content={seoTags.cloudSolutionService_seoTags.ogImage}
          />
          <meta
            property="og:url"
            content={seoTags.cloudSolutionService_seoTags.ogUrl}
          />
          <meta property="og:type" content="website" />
        </Helmet>
        <ServicesIntroSection
          icon={<Globe />}
          title="Introduction"
          description="We specialize in Digital Transformation, Cloud Solutions, AI & Machine Learning, and Market Analytics, empowering businesses to thrive in the digital era. At AlgoStrauss, we are committed to empowering your organization's growth."
        />
        <div className="bg-white xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange">
          <div className="flex flex-col xl:flex-row gap-2 md:gap-5 items-center">
            <div className="flex w-full gap-3 flex-1">
              <div>
                <CloudIcon />
              </div>
              <div className="">
                <h2 className="font-bold 2xl:text-4xl">
                  <span className="text-orange block sm:inline-block">
                    Cloud
                  </span>{" "}
                  Solutions
                </h2>
                <p className="font-medium hidden sm:block text-lg 2xl:text-xl text-[#1E1E1ECC]">
                  In today's rapidly evolving digital landscape, the cloud has
                  become the cornerstone of innovation and business scalability.
                  At AlgoStrauss, we understand the transformative potential of
                  cloud solutions, and our mission is to guide your organization
                  through a seamless migration to the cloud, harnessing its full
                  capabilities.
                </p>
              </div>
            </div>
            <p className="font-medium sm:hidden text-lg text-[#1E1E1ECC]">
              At AlgoStrauss, we understand that the future belongs to those who
              embrace digital transformation. Our mission is to guide businesses
              on their transformational journey by leveraging cutting-edge
              technologies and innovative strategies.
            </p>
            <div className="flex-1">
              <div className="flex gap-3 sm:gap-0 items-center justify-around">
                <img className="h-16 sm:h-auto" src={bannerIcon1} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon2} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon3} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon4} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="xsm:px-3 sm:px-20 py-10 sm:py-16 bg-black text-white">
          <h2 className="border-b-2 border-orange font-bold w-max mx-auto mb-10 xl:mb-20">
            What We Offer
          </h2>
          <div className="md:hidden">
            {offers.map((offer, i) => (
              <>
                <div
                  key={i}
                  className="flex items-center my-8 odd:flex-row-reverse"
                >
                  <div className="flex flex-1 gap-3">
                    <img className="h-max" src={offer.icon} alt="" />
                    <div>
                      <h3>{offer.title}</h3>
                      <div className="h-max">
                        <img
                          className="m-auto h-max rounded-lg"
                          src={offer.img}
                          alt=""
                        />
                      </div>
                      <p className="text-[#FFFFFFE5]">{offer.desc}</p>
                      {/* <button className='bg-orange px-[19px] py-[5px] rounded-md'>Get A Quote</button> */}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="hidden md:block 3xl:max-w-[85em] m-auto">
            {offers.map((offer, i) => (
              <>
                <div
                  key={i}
                  className="flex items-center my-8 even:flex-row-reverse"
                >
                  <div className="flex flex-1 gap-3">
                    <img className="h-max" src={offer.icon} alt="" />
                    <div>
                      <h3>{offer.title}</h3>
                      <p className="text-[#FFFFFFE5] 2xl:text-xl">
                        {offer.desc}
                      </p>
                      {/* <button className='bg-orange px-[19px] py-[5px] rounded-md xl:text-xl'>Get A Quote</button> */}
                    </div>
                  </div>
                  <div className="flex-1">
                    <img
                      className="m-auto h-max rounded-lg"
                      src={offer.img}
                      alt=""
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange text-white">
          <h3 className="text-[#1E1E1E] md:hidden mx-auto pt-3 mb-10 font-bold border-b-2 border-orange w-max">
            <span className="text-orange">Our</span> Other Services
          </h3>
          <div className="flex justify-center flex-col md:flex-row gap-10">
            <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
              <AiIcon className={"m-auto"} />
              <h4 className="text-center mt-2">
                <span className="text-orange">AI</span> and ML Solutions
              </h4>
              <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                Transforming Possibilities into Realities with{" "}
                <span className="text-orange">AlgoStrauss</span>
              </p>
              <div className="text-center">
                <button
                  onClick={() => navigate("/services/AI-ML-solutions")}
                  className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                >
                  Explore
                </button>
              </div>
            </div>
            <div>
              <h3 className="text-[#1E1E1E] mx-auto pt-3 hidden md:block mb-10 font-bold border-b-2 border-orange w-max">
                <span className="text-orange">Our</span> Other Services
              </h3>
              <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
                <NetworkIcon className={"m-auto"} />
                <h4 className="text-center mt-2">
                  <span className="text-orange">Market</span> Analytics
                </h4>
                <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                  {" "}
                  Seize Opportunities of Market with{" "}
                  <span className="text-orange">AlgoStrauss</span>
                </p>
                <div className="text-center">
                  <button
                    onClick={() => navigate("/services/market-analytics")}
                    className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                  >
                    Explore
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
              <CursorGlobeSmall className={"m-auto [&_svg]:m-auto"} />
              <h4 className="text-center mt-2">
                <span className="text-orange">Digital</span> Transformation
              </h4>
              <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                Empowering Your Digital Journey with{" "}
                <span className="text-orange">AlgoStrauss</span>
              </p>
              <div className="text-center">
                <button
                  onClick={() => navigate("/services/digital-transformation")}
                  className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                >
                  Explore
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-form">
        <ContactForm />
      </div>
    </Layout>
  );
};

export default CloudServices;
