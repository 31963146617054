import React from "react";
import { readMoreItems } from "./config/utils";
import ReactDOM from "react-dom/client";
import "./index.css";
import Homepage from "./pages/Homepage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DigitalServices from "./pages/DigitalServices";
import CloudServices from "./pages/CloudServices";
import MarketServices from "./pages/MarketServices";
import AiServices from "./pages/AiServices";
import WebExperties from "./pages/WebExperties";
import SoftwareExperties from "./pages/SoftwareExperties";
import DataExperties from "./pages/DataExperties";
<link
  href="https://fonts.googleapis.com/css?family=YOUR_SELECTED_FONTS"
  rel="stylesheet"
/>;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/services/digital-transformation" element={<DigitalServices />} />
        <Route exact path="/services/cloud-solutions" element={<CloudServices />} />
        <Route exact path="/services/AI-ML-solutions" element={<AiServices />} />
        <Route exact path="/services/market-analytics" element={<MarketServices />} />
        <Route exact path="/expertise/web-development" element={<WebExperties />} />
        <Route exact path="/expertise/software-development" element={<SoftwareExperties />} />
        <Route exact path="/expertise/data-science" element={<DataExperties />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
