import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import "../css/SubmitForm.scss";
import { useState } from "react";
import { isNotEmptyObject } from "../commonMethods/commonMethods";
import axios from "axios";

const SubmitForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function postData() {
    let data = {
      Name: formData.name,
      Email: formData.email,
      Mobile_Number: formData.phone,
      Message: formData.message,
    };

    try {
      axios
        .post(
          "https://sheet.best/api/sheets/d0d335dc-c3e4-4a57-adcb-0989a038095f",
          data
        )
        .then((response) => {
          console.log(response);
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
          toast.success("You have submitted successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          console.log("Error:", error);
          toast.error("An error occurred. Please try again later", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (err) {
      console.log("Error:", err);
      toast.error("An error occurred. Please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newFormErrors = {};
    for (const field in formData) {
      if (!formData[field]) {
        newFormErrors[field] = "This field is required";
      }
    }

    setFormErrors(newFormErrors);
    if (isNotEmptyObject(newFormErrors)) {
      return 1;
    } else {
      await postData();
    }
  };

  return (
    <div className="form-container">
      <form className="form" onSubmit={handleSubmit}>
        <div className="field-container">
          <input
            type="text"
            name="name"
            value={formData.name}
            placeholder="Your full name"
            className="full-width input-form"
            onChange={handleInputChange}
          />
          {formErrors.name && (
            <p className="error-message">{formErrors.name}</p>
          )}
        </div>
        <div className="field-container">
          <div className="input-container">
            {" "}
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Your email"
              className="input-form-email"
              onChange={handleInputChange}
            />
            &nbsp;{" "}
            {formErrors.email && (
              <p className="error-message">{formErrors.email}</p>
            )}
          </div>
          <div className="input-container">
            <input
              type="text"
              name="phone"
              value={formData.phone}
              placeholder="Your phone"
              className="input-form-phone"
              onChange={handleInputChange}
            />
            &nbsp;{" "}
            {formErrors.phone && (
              <p className="error-message">{formErrors.phone}</p>
            )}
          </div>
        </div>
        <div className="field-container">
          <textarea
            name="message"
            value={formData.message}
            placeholder="Your message"
            className="input-textarea-form"
            rows="5"
            onChange={handleInputChange}
          />
          {formErrors.message && (
            <p className="error-message">{formErrors.message}</p>
          )}
        </div>
        <button type="submit" className="submit-btn">
          Get A Quote
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default SubmitForm;
