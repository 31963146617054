import "../css/Footer.scss";
import { EN_DATA } from "../languages/EN";
import linkedin_icon from "../assets/icons/linkedin_icon.png";
import instagram_icon from "../assets/icons/instagram_icon.png";

export const Footer = () => {
  const footer = EN_DATA.homepage_management.footer_section;
  return (
    <div>
      <div className="footer_main">
        <div className="footer_logo">
          <img
            className="footer_company_logo"
            src={footer.logo}
            alt="Company Logo"
          />
        </div>
        {/* <div className="linkedin_icon">
          
        </div> */}
        <div className="sub_container">
          {footer.content.map((item, index) => (
            <div className="footer-link-section" key={index}>
              <div className="section-title">{item.heading}</div>
              <ul className="links-container">
                {item.links.length &&
                  item.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <a className="anchor-link" href={Object.values(link)}>
                        {Object.keys(link)}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="footer_main_copy_write border-t-4 border-orange">
        <div className="copy_write">
          Copyright © 2023 AlgoStrauss. All Rights Reserved.
        </div>
        <div className="footer_logo_copy_write">
          <div className="footer_social_links">
            <a href="https://www.linkedin.com/company/algostrauss/">
              <img
                className="footer_linkedin_logo"
                src={linkedin_icon}
                alt="linkedin icon"
              />
            </a>
            <a href="https://instagram.com/algostrauss?igshid=MmU2YjMzNjRlOQ==">
              <img
                className="footer_linkedin_logo"
                src={instagram_icon}
                alt="instagram icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
