import React, { useState, useEffect, useRef } from "react";
import "../css/Navigationbar.scss";
import logo from "../assets/icons/AlgostraussLogo.svg";
import search from "../assets/icons/search.svg";
// import { ReactComponent as HamIcon } from "../assets/icons/HamburgerIcon.svg";
import { ReactComponent as HamIcon } from "../assets/icons/icons8-hamburger.svg";

import { Link, useNavigate } from "react-router-dom";
import { scrollToContactForm } from "../commonMethods/commonMethods";
const Navigationbar = () => {
  const [triggerHamburger, setTriggerHamburger] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [navbarAtTop, setNavbarAtTop] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownExpertiseVisible, setDropdownExpertiseVisible] =
    useState(false);
  const Navigate = useNavigate();

  const serviceButton = useRef(null);
  const expertiseButton = useRef(null);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const { scrollY } = window;
      if (scrollY === 0) {
        setNavbarAtTop(true);
      } else {
        setNavbarAtTop(false);
      }
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  useEffect(() => {
    const handler = (e) => {
      if (serviceButton.current.contains(e.target)) {
        setDropdownVisible(true);
      } else {
        setDropdownVisible(false);
      }

      if (expertiseButton.current.contains(e.target)) {
        setDropdownExpertiseVisible(true);
      } else {
        setDropdownExpertiseVisible(false);
      }
    };

    document.addEventListener("mouseover", handler);
    return () => {
      document.removeEventListener("mouseover", handler);
    };
  });

  return (
    <React.Fragment>
      {/* For desktop */}
      <div
        className="navbar-desktop-container"
        style={{
          backgroundColor: navbarAtTop && "rgba(0, 0, 0, 0)",
        }}
      >
        <div
          className="navbar-image-section"
          onClick={() => Navigate("/")}
          onKeyDown={() => Navigate("/")}
          tabIndex={0}
          role="button"
          type="button"
        >
          <img src={logo} alt="" />
        </div>
        <div className="navbar-services-section !font-outfit tracking-wide whitespace-nowrap">
          {/* <div className="service-item" onClick={() => Navigate("/")}>Home</div> */}
          <div ref={serviceButton} className="relative">
            <button
              className={`service-item inline-flex items-center ${
                dropdownVisible && "!text-orange"
              }`}
              type="button"
            >
              Services
            </button>

            <div
              className={`z-10 ${
                !dropdownVisible && "hidden"
              } bg-white divide-y divide-gray-100 rounded-lg shadow w-max absolute top-full left-0`}
            >
              <ul className="pt-2 px-0 text-sm text-gray-700 w-full">
                <li>
                  <Link
                    to="/services/AI-ML-solutions"
                    className="no-underline block px-2 py-2 text-orange"
                  >
                    AI/ML Solutions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/cloud-solutions"
                    className="no-underline block px-2 py-2 text-orange"
                  >
                    Cloud Solutions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/digital-transformation"
                    className="no-underline block px-2 py-2 text-orange"
                  >
                    Digital Transformation
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/market-analytics"
                    className="no-underline block px-2 pt-2 text-orange"
                  >
                    Market Analytics
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div ref={expertiseButton} className="relative">
            <button
              className={`service-item inline-flex items-center ${
                dropdownExpertiseVisible && "!text-orange"
              }`}
              type="button"
            >
              Expertise
            </button>

            <div
              className={`z-10 ${
                !dropdownExpertiseVisible && "hidden"
              } bg-white divide-y divide-gray-100 rounded-lg shadow w-max absolute top-full left-0`}
            >
              <ul className="pt-2 px-0 text-sm text-gray-700 w-full">
                <li>
                  <Link
                    to="/expertise/web-development"
                    className="no-underline block px-2 py-2 text-orange"
                  >
                    Web Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/expertise/data-science"
                    className="no-underline block px-2 py-2 text-orange"
                  >
                    Data Science
                  </Link>
                </li>
                <li>
                  <Link
                    to="/expertise/software-development"
                    className="no-underline block px-2 py-2 text-orange"
                  >
                    Software Development
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="service-item">Expertise</div> */}
          <div className="service-item">Products</div>
          <div className="service-item">Online Training</div>
          <div className="service-item">About Us</div>
          <div
            className="service-item-contact-us"
            onClick={scrollToContactForm}
          >
            Contact Us
          </div>
        </div>
      </div>
      {/* For mobile */}
      <div className="navbar-mobile-container ">
        <div className="navbar-mobile-content">
          <div
            onClick={() => Navigate("/")}
            onKeyDown={() => Navigate("/")}
            tabIndex={0}
            role="button"
            type="button"
          >
            <img className="h-10" src={logo} alt="logo" />
          </div>
          <div
            onClick={() => setTriggerHamburger(!triggerHamburger)}
            onKeyDown={() => setTriggerHamburger(!triggerHamburger)}
            tabIndex={0}
            role="button"
            type="button"
          >
            <HamIcon />
          </div>
        </div>
        {triggerHamburger && (
          <div className="navbar-mobile-services">
            <div
              ref={serviceButton}
              className={`service-item-mobile w-max m-auto ${
                dropdownVisible && "first:pb-0" && "!text-orange"
              }`}
            >
              <div className="relative">
                <div>Services</div>
                <div
                  className={`z-10 ${
                    !dropdownVisible ? "h-0" : "h-max"
                  } overflow-hidden backdrop-blur-md m-auto divide-y divide-gray-100 rounded-lg shadow w-max`}
                >
                  <ul className="pt-2 px-0 text-sm text-gray-700 w-full">
                    <li>
                      <Link
                        to="/services/AI-ML-solutions"
                        className="no-underline block px-2 py-2 text-orange"
                      >
                        AI/ML Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/cloud-solutions"
                        className="no-underline block px-2 py-2 text-orange"
                      >
                        Cloud Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/digital-transformation"
                        className="no-underline block px-2 py-2 text-orange"
                      >
                        Digital Transformation
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/market-analytics"
                        className="no-underline block px-2 pt-2 text-orange"
                      >
                        Market Analytics
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              ref={expertiseButton}
              className={`service-item-mobile w-max m-auto ${
                dropdownExpertiseVisible && "first:pb-0" && "!text-orange"
              }`}
            >
              <div className="relative">
                <div>Expertise</div>
                <div
                  className={`z-10 ${
                    !dropdownExpertiseVisible ? "h-0" : "h-max"
                  } overflow-hidden backdrop-blur-md m-auto divide-y divide-gray-100 rounded-lg shadow w-max`}
                >
                  <ul className="pt-2 px-0 text-sm text-gray-700 w-full">
                    <li>
                      <Link
                        to="/expertise/web-development"
                        className="no-underline block px-2 py-2 text-orange"
                      >
                        Web Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/expertise/data-science"
                        className="no-underline block px-2 py-2 text-orange"
                      >
                        Data Science
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/expertise/software-development"
                        className="no-underline block px-2 py-2 text-orange"
                      >
                        Software Development
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="service-item-mobile">
              <div>Expertise</div>
            </div> */}
            <div className="service-item-mobile">
              <div>Products</div>
            </div>
            <div className="service-item-mobile">
              <div>Online Training</div>
            </div>
            <div className="service-item-mobile">
              <div>About Us</div>
            </div>
            <div className="centerElement">
              <div className="service-item-contact-us">
                <div>Contact Us</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Navigationbar;
