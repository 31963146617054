import React from 'react';
import "../css/Homepage.scss";
import { ReactComponent as SkillImg1 } from "../assets/icons/AlgoSkills1.svg";
import { ReactComponent as SkillImg2 } from "../assets/icons/AlgoSkills2.svg";
import { ReactComponent as SkillImg3 } from "../assets/icons/AlgoSkills3.svg";
import { ReactComponent as SkillImg4 } from "../assets/icons/AlgoSkills4.svg";

const SkillsSection = () => (
    <div className="skills-section [&_svg]:m-auto" data-aos="zoom-in">
        <div className="offering-section">
            <div>
                <SkillImg1 />
            </div>
            <div className="skillsContent">
                Highly skilled consultants from IITs and experienced professionals
                with up-to-date knowledge.
            </div>
        </div>
        <div className="offering-section">
            <div>
                <SkillImg2 />
            </div>
            <div className="skillsContent">
                Customer-centric approach that puts your satisfaction at the
                forefront.
            </div>
        </div>
        <div className="offering-section">
            <div>
                <SkillImg3 />
            </div>
            <div className="skillsContent">
                Competitive pricing without compromising on quality.
            </div>
        </div>
        <div className="offering-section">
            <div>
                <SkillImg4 />
            </div>
            <div className="skillsContent">
                Proven track record of successful projects across diverse
                industries.
            </div>
        </div>
    </div>
);

export default SkillsSection;