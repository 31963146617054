import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import bannerBg from "../assets/images/ServicePageBanner.png";
import {
  AiIcon,
  CloudIcon,
  CursorGlobe,
  Globe,
  NetworkIcon,
  ServiceDig1,
  ServiceDig2,
  ServiceDig3,
  ServiceDig4,
  ServiceDig5,
  ServiceDig6,
} from "../assets/images/svg/SvgImages";
import ContactForm from "../reusableComponents/ContactForm";
import { useNavigate } from "react-router-dom";
import {ServicesIntroSection} from "../reusableComponents/IntroSection";

const DigitalServices = () => {
  const navigate = useNavigate();

  const bannerIcon1 =
    "https://cdn.algostrauss.com/services/digital_transformation_icons/bannerIcon1.svg";
  const bannerIcon2 =
    "https://cdn.algostrauss.com/services/digital_transformation_icons/bannerIcon2.svg";
  const bannerIcon3 =
    "https://cdn.algostrauss.com/services/digital_transformation_icons/bannerIcon3.svg";
  const bannerIcon4 =
    "https://cdn.algostrauss.com/services/digital_transformation_icons/bannerIcon4.svg";

  const dig1 = "https://cdn.algostrauss.com/services/strategic_assessment.png";
  const dig2 = "https://cdn.algostrauss.com/services/technology_adoption.png";
  const dig3 = "https://cdn.algostrauss.com/services/process_optimization.png";
  const dig4 =
    "https://cdn.algostrauss.com/services/data_analytics_and_insights.png";
  const dig5 =
    "https://cdn.algostrauss.com/services/user_experience_enhancement.png";
  const dig6 =
    "https://cdn.algostrauss.com/services/security_and_compliance.png";

  const offers = [
    {
      title: "Strategic Assessment",
      desc: "Every successful digital transformation begins with a clear strategy. We assess your current processes, technology stack, and business goals to develop a tailored digital transformation roadmap.",
      img: dig1,
      icon: <ServiceDig1 />,
    },
    {
      title: "Technology Adoption",
      desc: "Every successful digital transformation begins with a clear strategy. We assess your current processes, technology stack, and business goals to develop a tailored digital transformation roadmap.",
      img: dig2,
      icon: <ServiceDig2 />,
    },
    {
      title: "Process Optimization",
      desc: "Streamline your operations for maximum efficiency. We analyze your workflows, identify bottlenecks, and implement process improvements to enhance productivity and reduce costs.",
      img: dig3,
      icon: <ServiceDig3 />,
    },
    {
      title: "Data Analytics and Insights",
      desc: "Unlock the power of data. Our data analytics solutions enable you to extract actionable insights, make data-driven decisions, and gain a competitive edge in your industry.",
      img: dig4,
      icon: <ServiceDig4 />,
    },
    {
      title: "User Experience Enhancement",
      desc: "Deliver exceptional user experiences. We revamp your digital interfaces, websites, and applications to ensure they are user-friendly, engaging, and aligned with your brand.",
      img: dig5,
      icon: <ServiceDig5 />,
    },
    {
      title: "Security and Compliance",
      desc: "Protect your digital assets and customer data. We implement robust cybersecurity measures and ensure compliance with industry regulations, maintaining the trust of your customers.",
      img: dig6,
      icon: <ServiceDig6 />,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="font-outfit">
        <ServicesIntroSection
          icon={<Globe />}
          title="Introduction"
          description="We specialize in Digital Transformation, Cloud Solutions, AI & Machine Learning, and Market Analytics, empowering businesses to thrive in the digital era. At AlgoStrauss, we are committed to empowering your organization's growth."
        />
        {/* <div style={{ backgroundImage: `url(${bannerBg})` }} className='bg-cover bg-no-repeat bg-center w-full h-max pt-20 sm:pt-32 pb-10 bg-[#005d85]'>
          <div className='flex justify-center items-center h-full max-w-[90%] sm:max-w-[60%] 2xl:max-w-[60%] m-auto'>
            <div className='text-white mt-14'>
              <div className='relative w-max'>
                <Globe className='absolute -top-[2.8em] sm:-top-[4em] transform rotate-90 sm:rotate-0 -right-[4.4em] sm:-left-[5.5em] h-20 sm:h-auto' />
                <h1 className='border-b-2 border-orange font-bold w-max xl:text-4xl 2xl:text-6xl'>Introduction</h1>
              </div>
              <div>
                <p className='text-lg xl:text-xl 2xl:text-3xl'></p>
                <button className='bg-orange rounded-md px-[19px] py-2.5 font-semibold text-lg xl:text-xl 2xl:text-3xl'>Get a Quote</button>
              </div>
            </div>
          </div>
        </div> */}
        <div className="bg-white xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange">
          <div className="flex flex-col xl:flex-row gap-2 md:gap-5 items-center">
            <div className="flex w-full gap-3 flex-1">
              <CursorGlobe />
              <div className="">
                <h2 className="font-bold 2xl:text-4xl">
                  <span className="text-orange block sm:inline-block">
                    Digital
                  </span>{" "}
                  Transformation
                </h2>
                <p className="font-medium hidden sm:block text-lg 2xl:text-xl text-[#1E1E1ECC]">
                  At AlgoStrauss, we understand that the future belongs to those
                  who embrace digital transformation. Our mission is to guide
                  businesses on their transformational journey by leveraging
                  cutting-edge technologies and innovative strategies.
                </p>
              </div>
            </div>
            <p className="font-medium sm:hidden text-lg text-[#1E1E1ECC]">
              At AlgoStrauss, we understand that the future belongs to those who
              embrace digital transformation. Our mission is to guide businesses
              on their transformational journey by leveraging cutting-edge
              technologies and innovative strategies.
            </p>
            <div className="flex-1">
              <div className="flex gap-3 sm:gap-0 items-center justify-around">
                <img className="h-16 sm:h-auto" src={bannerIcon1} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon2} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon3} alt="" />
                <img className="h-16 sm:h-auto" src={bannerIcon4} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="xsm:px-3 sm:px-20 py-10 sm:py-16 bg-black text-white">
          <h2 className="border-b-2 border-orange font-bold w-max mx-auto mb-10 xl:mb-20">
            What We Offer
          </h2>
          <div className="md:hidden">
            {offers.map((offer, i) => (
              <>
                <div
                  key={i}
                  className="flex items-center my-8 odd:flex-row-reverse"
                >
                  <div className="flex flex-1 gap-3">
                    {offer.icon}
                    <div>
                      <h3>{offer.title}</h3>
                      <div className="h-max">
                        <img
                          className="m-auto h-max rounded-lg"
                          src={offer.img}
                          alt=""
                        />
                      </div>
                      <p className="text-[#FFFFFFE5]">{offer.desc}</p>
                      {/* <button className='bg-orange px-[19px] py-[5px] rounded-md'>Get A Quote</button> */}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="hidden md:block 3xl:max-w-[85em] m-auto">
            {offers.map((offer, i) => (
              <>
                <div
                  key={i}
                  className="flex items-center my-8 even:flex-row-reverse"
                >
                  <div className="flex flex-1 gap-3">
                    {offer.icon}
                    <div>
                      <h3>{offer.title}</h3>
                      <p className="text-[#FFFFFFE5] 2xl:text-xl">
                        {offer.desc}
                      </p>
                      {/* <button className='bg-orange px-[19px] py-[5px] rounded-md xl:text-xl'>Get A Quote</button> */}
                    </div>
                  </div>
                  <div className="flex-1">
                    <img
                      className="m-auto h-max rounded-lg"
                      src={offer.img}
                      alt=""
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange text-white">
          <h3 className="text-[#1E1E1E] md:hidden mx-auto pt-3 mb-10 font-bold border-b-2 border-orange w-max">
            <span className="text-orange">Our</span> Other Services
          </h3>
          <div className="flex justify-center flex-col md:flex-row gap-10">
            <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
              <CloudIcon className={"m-auto"} />
              <h4 className="text-center mt-2">
                <span className="text-orange">Cloud</span> Solutions
              </h4>
              <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                Unlocking the Power of the Cloud with{" "}
                <span className="text-orange">AlgoStrauss</span>
              </p>
              <div className="text-center">
                <button
                  onClick={() => navigate("/services/cloud-solutions")}
                  className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                >
                  Explore
                </button>
              </div>
            </div>
            <div>
              <h3 className="text-[#1E1E1E] mx-auto pt-3 hidden md:block mb-10 font-bold border-b-2 border-orange w-max">
                <span className="text-orange">Our</span> Other Services
              </h3>
              <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
                <AiIcon className={"m-auto"} />
                <h4 className="text-center mt-2">
                  <span className="text-orange">AI</span> and ML Solutions
                </h4>
                <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                  Transforming Possibilities into Realities with{" "}
                  <span className="text-orange">AlgoStrauss</span>
                </p>
                <div className="text-center">
                  <button
                    onClick={() => navigate("/services/AI-ML-solutions")}
                    className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                  >
                    Explore
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-[#1E1E1E] w-auto xl:w-max px-3 py-2 rounded-2xl h-max">
              <NetworkIcon className={"m-auto"} />
              <h4 className="text-center mt-2">
                <span className="text-orange">Market</span> Analytics
              </h4>
              <p className="text-sm 2xl:text-lg xl:whitespace-nowrap text-center">
                Seize Opportunities of Market with{" "}
                <span className="text-orange">AlgoStrauss</span>
              </p>
              <div className="text-center">
                <button
                  onClick={() => navigate("/services/market-analytics")}
                  className="bg-orange px-3 py-1 rounded-lg m-auto hover:bg-white hover:text-orange active:scale-[.95]"
                >
                  Explore
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-form">
        <ContactForm />
      </div>
    </Layout>
  );
};

export default DigitalServices;
