import { EN_DATA } from "../languages/EN";
import "../css/HowWeWork.scss";
import { useEffect } from "react";
import { useState } from "react";
export const HowWeWork = () => {
  const data = EN_DATA.homepage_management.how_we_work_mgmt;
  const steps = data.steps_to_do; // Replace with your data
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      if (currentIndex + 1 === steps.length) {
        clearInterval(timer);
      }
    }, 2000);

    return () => clearInterval(timer);
  }, [steps.length, currentIndex]);
  return (
    <div className="how-we-work-container" data-aos="fade-up">
      <div className="main-heading" data-aos="fade-up" data-aos-delay={100}>
        {data.main_heading}
      </div>
      <div className="main-desc" data-aos="fade-up" data-aos-delay={300}>
        {" "}
        {data.main_desc}
      </div>
      <div className="steps-containers" data-aos="fade-up" data-aos-delay={300}>
        {data.steps_to_do.map((item, index) => (
          <div className="step-container" key={index}>
            <div
              className={`circle ${
                currentIndex === index ? "active" : "in-active"
              } `}
            >{`0${index + 1}`}</div>
            <div className="step-heading">{item.heading}</div>
            <div className="step-description">{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
