let DATA_SCIENCE_BG = "https://cdn.algostrauss.com/images/data_sicence1.JPG";
let WEB_DEVELOP_BG = "https://cdn.algostrauss.com/images/web_dev1.JPG";
let SOFTWARE_DEVELOP_BG =
  "https://cdn.algostrauss.com/images/software_develop1.JPG";
let WEB_DEV_LOGO = "https://cdn.algostrauss.com/icons/web_dev_logo.svg";
let DATA_SCIENCE_LOGO = "https://cdn.algostrauss.com/icons/Group.svg";
let SOFTWARE_DEVELOP_LOGO =
  "https://cdn.algostrauss.com/icons/carbon_development.svg";
let FooterLogo = "https://cdn.algostrauss.com/images/footer_logo.png";
export const EN_DATA = {
  homepage_management: {
    homepage_legacy: {
      heading: "LEGACY OF THE MAX GROUP",
      description: `Over the decades,the Max Group has built a well established legacy when
        it comes to trust and reliability. Taking these various forward, we have
        created an ecosystem that provides care, companionship and comfort to
        recuperating seniors. With a clear focus on offering exceptional primary
        health care and emergency response services in a homely
        environment,aimed at promoting holistic wellness and healing, we promise
        to care for your elders, just like your own.`,
    },
    homepage_clients_heading: "Our Clients",
    clients_feedback: {
      feedback_heading: "See what Our Customers Tell",
      feedback_description: [
        {
          name: "Mr. VIkas Sharma",
          feedback: `As my dad's caregiver, I needed a lightweight wheelchair that I could use with him anytime. After researching I connected with Antara. I was not only impressed with the quality of the product but also the technician that came over. He took his time to explain fully on the features and usability. Have been using it for a while now, I am impressed with the durability.`,
        },
        {
          name: "Mr. VIkas Sharma",
          feedback: `As my dad's caregiver, I needed a lightweight wheelchair that I could use with him anytime. After researching I connected with Antara. I was not only impressed with the quality of the product but also the technician that came over. He took his time to explain fully on the features and usability. Have been using it for a while now, I am impressed with the durability.`,
        },
        {
          name: "Mr. VIkas Sharma",
          feedback: `As my dad's caregiver, I needed a lightweight wheelchair that I could use with him anytime. After researching I connected with Antara. I was not only impressed with the quality of the product but also the technician that came over. He took his time to explain fully on the features and usability. Have been using it for a while now, I am impressed with the durability.`,
        },
      ],
    },
    subscribe_section: {
      sub_btn: "Subscribe",
      placeholder_txt: "Type your email",
    },
    footer_section: {
      logo: FooterLogo,
      content: [
        {
          heading: "Our Expertise",
          links: [
            { "Web Development": "/expertise/web-development" },
            { "Data Science": "/expertise/data-science" },
            { "Software Development": "/expertise/software-development" },
          ],
        },
        {
          heading: "Our Services",
          links: [
            { "Digital Transformation": "/services/digital-transformation" },
            {
              "AI and Machine Learning Solutions": "/services/AI-ML-solutions",
            },
            { "Cloud Solutions": "/services/cloud-solutions" },
            { "Market Analysis": "/services/market-analytics" },
          ],
        },
        {
          heading: "Online Training",
          links: [
            { "Web Development": "/expertise/web-development" },
            { "Data Science": "/expertise/data-science" },
            { "App Development": "/expertise/software-development" },
          ],
        },
        {
          heading: "Our Products",
          links: [],
        },
        {
          heading: "Company",
          links: [
            { "About Us": "/expertise/web-development" },
            { Legal: "/expertise/web-development" },
            { "Privacy Policy": "/expertise/web-development" },
            { "Responsible Disclosure": "/expertise/web-development" },
          ],
        },
      ],
    },
    how_we_work_mgmt: {
      main_heading: "How we do it?",
      main_desc:
        "We ensure that every project is a success, delivering quality, transparency, and value to our clients.",
      steps_to_do: [
        {
          heading: "Client-Centric Approach",
          description:
            "We prioritize clients' unique needs and goals, building strong collaborative foundations for every project.",
        },
        {
          heading: "Data-Driven Insights",
          description:
            "We harness advanced data science for valuable insights, empowering clients with actionable strategies for a competitive advantage.",
        },
        {
          heading: "Customized Solutions",
          description:
            "We craft innovative, scalable web and app solutions tailored to your unique vision and requirements.",
        },
        {
          heading: "Cutting-Edge Technology",
          description:
            "We're ahead in tech, using the latest tools to craft modern, efficient digital solutions, ensuring you stay ahead too.",
        },
      ],
    },
    expertise_mgmt: {
      heading: "Our Expertise",
      expertise_items: [
        {
          bg_image: WEB_DEVELOP_BG,
          heading: "Web Development",
          description:
            "With a strong focus on web development, we offer comprehensive training in full-stack, front-end, and back-end technologies, empowering you to create dynamic and engaging web solutions",
          logo: WEB_DEV_LOGO,
          activeIcon:
            "https://cdn.algostrauss.com/experise/web_development_big_wight.png",
          icon: "https://cdn.algostrauss.com/experise/web_development_big_orange.png",
          webPageLink: "/expertise/web-development",
        },
        {
          bg_image: DATA_SCIENCE_BG,
          heading: "Data Science",
          description:
            "Our proficiency in data science shines through in our tailored training programs, encompassing data analysis, machine learning, data engineering, and AI. ",
          logo: DATA_SCIENCE_LOGO,
          activeIcon:
            "https://cdn.algostrauss.com/experise/data_science_big_white.png",
          icon: "https://cdn.algostrauss.com/experise/data_science_big_orange.png",
          webPageLink: "/expertise/data-science",
        },
        {
          bg_image: SOFTWARE_DEVELOP_BG,
          heading: "Software Development",
          description:
            "Our expertise in software development spans front-end, back-end, database management, and software architecture Empower yourself to craft efficient and innovative Software. ",
          logo: SOFTWARE_DEVELOP_LOGO,
          activeIcon:
            "https://cdn.algostrauss.com/experise/software_development_white.png",
          icon: "https://cdn.algostrauss.com/experise/software_development_big_orange.png",
          webPageLink: "/expertise/software-development",
        },
      ],
    },
    contact_mgmt: {
      heading: "LET'S TALK",
      sub_heading: "Get In Touch With Us",
      description:
        "Take the next step towards your goals by completing the form below. We're here to assist you, and providing us with this information will help us tailor our services to your specific needs.",
    },
  },
};

export const SUBMIT_URL = "https://www.npmjs.com/package/google-spreadsheet";
