import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import ContactForm from "../reusableComponents/ContactForm";
import { ExpertiesIntroSection } from "../reusableComponents/IntroSection";
import { CloudIcon, CursorGlobe, Globe } from "../assets/images/svg/SvgImages";
import { useNavigate } from "react-router-dom";
import { EN_DATA } from "../languages/EN";
import line from "../assets/images/line.svg";
import ExpertiesWhyUs from "../reusableComponents/ExpertiesWhyUs";

const WebExperties = () => {
  const navigate = useNavigate();

  const expertise = [
    {
      title: "Responsive Web Design",
      description:
        "In today's digital age, having a responsive website is crucial. We specialize in creating websites that adapt seamlessly to different screen sizes, ensuring an optimal user experience on desktops, tablets, and mobile devices.",
      icon: "https://cdn.algostrauss.com/experise/web+development/responsive_web_design.png",
    },
    {
      title: "Content Management Systems (CMS)",
      description:
        "Managing and updating your website should be a breeze. We work with popular CMS platforms like WordPress, Drupal, and Joomla to provide you with easy-to-use interfaces for content management.",
      icon: "https://cdn.algostrauss.com/experise/web+development/content management systems.png",
    },
    {
      title: "E-commerce Development",
      description:
        "Selling products or services online? Our e-commerce development team can create robust and secure online stores that drive sales and enhance user engagement.",
      icon: "https://cdn.algostrauss.com/experise/web+development/e_commerce_development.png",
    },
    {
      title: "Web Application Development",
      description:
        "From simple web apps to complex enterprise solutions, our team can develop web applications that streamline your business processes and improve efficiency.",
      icon: "https://cdn.algostrauss.com/experise/web+development/web application development.png",
    },
    {
      title: "Performance Optimization",
      description:
        "Slow websites can drive visitors away. We optimize website performance, ensuring fast load times and smooth user interactions.",
      icon: "https://cdn.algostrauss.com/experise/web+development/performance optimization.png",
    },
    {
      title: "Security and Compliance",
      description:
        "Protecting your website and user data is a top priority. We implement security measures and ensure compliance with industry standards and regulations.",
      icon: "https://cdn.algostrauss.com/experise/web+development/security and compliance.png",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <ExpertiesIntroSection
        icon="https://cdn.algostrauss.com/experise/banner_icon.png"
        title="Introduction"
        description="We specialize in Digital Transformation, Cloud Solutions, AI & Machine Learning, and Market Analytics, empowering businesses to thrive in the digital era. At AlgoStrauss, we are committed to empowering your organization's growth."
      />
      <div className="bg-white xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange">
        <div className="flex flex-col xl:flex-row gap-2 md:gap-5 items-center">
          <div className="flex w-full gap-3 flex-[1.3]">
            <div>
              <CursorGlobe />
            </div>
            <div className="">
              <h2 className="font-bold 2xl:text-4xl">
                <span className="text-orange block sm:inline-block">Web</span>{" "}
                Development
              </h2>
              <p className="font-medium hidden sm:block text-lg 2xl:text-xl text-[#1E1E1ECC]">
                In today's rapidly evolving digital landscape, the cloud has
                become the cornerstone of innovation and business scalability.
                At AlgoStrauss, we understand the transformative potential of
                cloud solutions, and our mission is to guide your organization
                through a seamless migration to the cloud, harnessing its full
                capabilities.
              </p>
            </div>
          </div>
          <p className="font-medium sm:hidden text-lg text-[#1E1E1ECC]">
            At AlgoStrauss, we understand that the future belongs to those who
            embrace digital transformation. Our mission is to guide businesses
            on their transformational journey by leveraging cutting-edge
            technologies and innovative strategies.
          </p>
          <div className="flex-1">
            <img
              className="max-h-60 m-auto"
              src="https://cdn.algostrauss.com/experise/experise_icon1.png"
              alt="icon"
            />
          </div>
        </div>
      </div>

      <div className="bg-black xsm:px-2 md:px-20 py-10">
        <h2 className="border-b-2 border-orange font-bold w-max mb-10 xl:mb-20 text-white">
          Our Expertise
        </h2>
        <div className="grid lg:grid-cols-2 gap-x-10 gap-y-20 mt-5">
          {expertise.map((exp, i) => {
            const title = exp.title.split(" ");
            const first = title[0];
            const rest = title.splice(1).join(" ");
            console.log(rest);

            return (
              <div className="bg-[#F6F6F6] relative py-4 pl-20 pr-4 shadow-[6px_6px_10px_rgba(255,135,0,.5)] rounded-sm">
                <h1 className="text-3xl font-bold">
                  <span className="text-orange">{first} </span>
                  {rest}
                </h1>
                <p className="font-medium">{exp.description}</p>
                <div className="absolute -top-12 left-2 h-20 w-20">
                  <div className="pentagon bg-orange h-full w-full flex items-center justify-center [&_svg]:m-auto">
                    <img src={exp.icon} alt="" />
                  </div>
                  <img
                    className="absolute h-full w-max bg-orange top-[98%] left-[50%] -translate-x-[50%]"
                    src={line}
                    alt="line"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="xsm:px-3 sm:px-20 py-10 sm:py-16 border-y-4 border-orange text-white">
        <h3 className="text-[#1E1E1E] mx-auto pt-3 mb-10 font-bold border-b-2 border-orange w-max">
          <span className="text-orange">Our</span> Other Expertises
        </h3>
        <div className="grid lg:grid-cols-3 gap-10">
          {EN_DATA.homepage_management.expertise_mgmt.expertise_items.map(
            (exp, i) => {
              let first;
              let rest;
              const headings = exp.heading.split(" ");
              first = headings[0];
              rest = headings[1];

              let thisPage = first.includes("Web");
              console.log(thisPage);

              return (
                <div
                  className={`${
                    thisPage ? "bg-orange" : "bg-[#1E1E1E]"
                  } w-auto xl:w-max px-3 py-2 rounded-lg h-full shadow-[0px_4px_10px_rgba(0,0,0,0.5)]`}
                >
                  <img
                    className="m-auto h-16"
                    src={thisPage ? exp.activeIcon : exp.icon}
                    alt="icon"
                  />
                  <h4 className="text-center mt-2 font-bold">
                    <span
                      className={`${thisPage ? "text-black" : "text-orange"}`}
                    >
                      {first}
                    </span>{" "}
                    {rest}
                  </h4>
                  <p className="text-sm 2xl:text-lg text-center font-medium">
                    {exp.description}
                  </p>
                  <div className="text-center">
                    <button
                      onClick={() => navigate(exp.webPageLink)}
                      className={`${
                        thisPage
                          ? "hidden"
                          : "bg-orange hover:bg-white hover:text-orange"
                      } px-3 py-1 rounded-lg m-auto active:scale-[.95]`}
                    >
                      Explore
                    </button>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>

      <ExpertiesWhyUs />

      <div id="contact-form">
        <ContactForm />
      </div>
    </Layout>
  );
};

export default WebExperties;
