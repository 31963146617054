export function isNotEmptyObject(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return true; // If there is at least one property, it's not empty
    }
  }
  return false; // If no properties found, it's empty
}

export const scrollToContactForm = (ev) => {
  ev.preventDefault();
  const contactFormElement = document.getElementById("contact-form");
  if (contactFormElement) {
    contactFormElement.scrollIntoView({ behavior: "smooth" });
  }
};
