import React from 'react';

const ExpertiesWhyUs = () => {


    const whyUs = [
        {
            title: "Expert Guidance",
            description: "Our team comprises experienced consultants with a deep understanding of digital technologies and their impact on business. We provide the strategic guidance you need to navigate the digital landscape successfully.",
            icon: 'https://cdn.algostrauss.com/experise/expert_guidance.png'
        },
        {
            title: "Proven Results",
            description: "AlgoStrauss has a track record of helping businesses achieve tangible results through digital transformation. We measure success by improved efficiency, increased revenue, and enhanced customer satisfaction.",
            icon: 'https://cdn.algostrauss.com/experise/proven_results.png'
        },
        {
            title: "Collaborative Approach",
            description: "We believe in working closely with our clients throughout the transformation process. Your input and feedback are integral to our partnership, ensuring that the final solutions align with your vision.",
            icon: 'https://cdn.algostrauss.com/experise/collaborative_approach.png'
        },
        {
            title: "Collaborative Approach",
            description: "We believe in working closely with our clients throughout the transformation process. Your input and feedback are integral to our partnership, ensuring that the final solutions align with your vision.",
            icon: 'https://cdn.algostrauss.com/experise/collaborative_approach2.png'
        },
        {
            title: "Future-Ready",
            description: "We don't just focus on short-term gains. Our digital transformation strategies are designed to future-proof your business, ensuring its relevance and success in the ever-evolving digital landscape.",
            icon: 'https://cdn.algostrauss.com/experise/future_ready.png'
        },
        {
            title: "Customer-Centric Focus",
            description: "At AlgoStrauss, our customers are at the heart of everything we do. We prioritize understanding your unique needs and goals to deliver solutions that exceed your expectations.",
            icon: 'https://cdn.algostrauss.com/experise/customer_centric_focus.png'
        },
    ]

    return (
        <div className={`bg-[url('https://cdn.algostrauss.com/experise/why_choose_us_1.png')] md:p-20`}>
            <div className='bg-[#151414B2] py-3 px-5 text-white'>
                <h1 className=" mx-auto  font-bold border-b-2 border-orange w-max mb-8">
                    Why Choose <span className="text-orange">us?</span>
                </h1>
                <div className='grid lg:grid-cols-2 items-center mt-4'>
                    {
                        whyUs.map((item, i) => (
                            <div className='flex flex-col md:flex-row max-w-[25em] items-center gap-2 even:ml-auto'>
                                <img src={item.icon } alt="" />
                                <div>
                                    <h1 className='text-orange text-3xl'>{item.title}</h1>
                                    <p className='text-sm tracking-wide'>{item.description}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
    );
}

export default ExpertiesWhyUs;
